<template>
  <div class="inner-section">
  <card>
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">
            {{ $t('externalTraining.trainee_evaluation') }}
        </h4>
      </template>
      <template v-slot:headerAction>
        <router-link class="btn-add" to="trainee-evaluation">{{ $t('externalTraining.trainee_evaluation') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-overlay :show="load">
              <b-row>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required|min:2">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Batch" vid="batch_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="batch_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('externalTraining.batch')}} <span class="text-danger"></span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.batch_id"
                        :options="batchList"
                        id="batch_id"
                        :state="errors[0] ? false : (valid ? true : null)"

                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- <b-col lg="6" sm="6"></b-col> -->
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Mark" vid="total_mark" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="total_mark"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.mark')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                          type="number"
                          id="total_mark"
                          v-model="formData.total_mark"
                      ></b-form-input>
                      <!-- <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div> -->
                      <span class="d-block invalid-feedback" v-if="errorArr['total_mark']">
                        {{$t('externalTraining.total_mark_validation_when_submit')}}
                      </span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <br/>
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('externalTraining.trainee_evaluation')}} {{$t('globalTrans.list')}}</h4>
                </template>
                <template v-slot:body>
                  <b-table-simple hover small caption-top responsive bordered>
                    <b-tr v-for="(bd,index) in formData.batchDetails" :key="index">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-th class="text-left" colspan="6">
                              {{$t('elearning_config.batch')}} : {{ ($i18n.locale==='bn') ? $store.state.ExternalUserService.trainingPanel.commonObj.batchList.find(item => item.value === bd).text_bn : $store.state.ExternalUserService.trainingPanel.commonObj.batchList.find(item => item.value === bd).text_en }}
                            </b-th>
                          </b-tr>
                          <b-tr>
                            <b-th class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                            <b-th>{{$t('globalTrans.name')}}</b-th>
                            <b-th>{{$t('globalTrans.mobile')}}</b-th>
                            <b-th>{{$t('globalTrans.organization')}}</b-th>
                            <b-th>{{$t('elearning_config.batch')}}</b-th>
                            <b-th class="text-center">{{$t('externalTraining.obtained_mark')}}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody v-for="(train,index) in formData.details" :key="index">
                            <b-tr v-if="bd === train.batch_id">
                              <b-td class="text-center">{{$n(index+1)}}</b-td>
                              <b-td>
                                <!-- {{ ($i18n.locale==='bn') ? train.name_bn : train.name }} -->
                                {{ ($i18n.locale==='bn') ? train.personal_info.name_bn : train.personal_info.name }}
                              </b-td>
                              <b-td>
                                {{ train.mobile }}
                              </b-td>
                              <b-td>
                                {{ ($i18n.locale==='bn') ? train.org_bn : train.org }}
                              </b-td>
                              <b-td>
                                {{ ($i18n.locale==='bn') ? train.batch_bn : train.batch }}
                              </b-td>
                              <b-td>
                                <b-col>
                                  <!-- <b-form-checkbox
                                  :disabled="train.batch_id ? parseInt(train.batch_id) === parseInt(formData.batch_id) ? false : true : false"
                                    :id="'checkbox-' + train.id"
                                    v-model="train.select"
                                    :name="'checkbox-' + train.id"
                                    :value=1
                                    unchecked-value=0
                                  >
                                  </b-form-checkbox> -->
                                </b-col>
                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                  <ValidationProvider name="obtained_mark" :vid="'obtained_mark-' + train.id" rules="required">
                                      <b-form-group
                                        label-for="service_id"
                                        slot-scope="{ valid, errors }"
                                      >
                                      <b-form-input
                                        type="number"
                                        :id="'obtained_mark-' + train.id"
                                        :name="'obtained_mark-' + train.id"
                                        v-model="train.obtained_mark"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <!-- <div class="invalid-feedback">
                                          {{ errors.details}}
                                      </div> -->
                                      <span class="d-block invalid-feedback" v-if="errorArr['details.'+index+'.obtained_mark']">
                                        {{$t('externalTraining.obtained_mark_less_than_total_mark')}}
                                      </span>
                                      </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-td>
                            </b-tr>
                        </b-tbody>
                      </b-table-simple>
                      <!-- <b-td>
                      </b-td> -->
                    </b-tr>
                    <template v-if="formData.details.length === 0">
                      <tr>
                        <th colspan="6" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                      </tr>
                    </template>
                  </b-table-simple>
                </template>
              </body-card>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <b-button v-if="hasTotalOrObtainedMark === true" type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.update') }}</b-button>
                  <b-button v-else type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                  <router-link to="trainee-evaluation" class="mr-1 btn btn-danger btn-sm">{{ $t('globalTrans.cancel') }}</router-link>
                </div>
              </div>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </template>
    </body-card>
    </card>
  </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerBaseSpecificCircularList, manualCourseEvaluationStore, trainerCircularMemoSpecificList, trainerSpecificCircularList, trainerCircularOrgSpecificList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    data () {
      return {
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        errorArr: [],
        search: {
          org_id: 0,
          batch_id: 0,
          mobile: ''
        },
        formData: {
          registration_for: 2,
          circular_memo_no: 0,
          fiscal_year_id: 0,
          org_id: 0,
          training_type_id: 0,
          training_title_id: 0,
          batch_id: 0,
          sort: 2,
          details: [],
          batchDetails: [],
          total_mark: 0,
          obtained_mark: 0
        },
        officeTypeList: [],
        officeList: [],
        trainingCategoryList: [],
        trainingTitleList: [],
        load: false,
        // loading: false,
        detailsErrors: [],
        batchList: [],
        circularList: [],
        circularLoading: false,
        circularErrorMsg: false,
        trainerProfileError: '',
        hasTotalOrObtainedMark: false
      }
    },
    computed: {
      orgList: function () {
        return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      trainingTypeList: function () {
        return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      async getCircularList () {
        this.circularLoading = true
        // const serchData = {
        //   // org_id: this.$store.state.dataFilters.orgId
        // }
        const params = {
          trainerId: this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerSpecificCircularList, params)
        if (!result.success) {
        } else {
          const listObject = result.circularMemoNo
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      async getBatchList (orgId) {
        // const batchList = this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.filter(item => item.status === 1)
        // if (orgId) {
        //   return batchList.filter(item => item.org_id === orgId)
        // }
        // return

        const params = {
          circular_memo_no: this.formData.circular_memo_no,
          org_id: orgId,
          personal_info_id: this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id,
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_title_id: this.formData.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerCircularOrgSpecificList, params)
        if (result.success) {
          const batchList = this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.filter(item => item.status === 1 && item.org_id === orgId && result.circularOrgSpecificBatchList.includes(item.value)).map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
              } else {
                return { value: item.value, text: item.text_en }
              }
          })
          this.batchList = batchList
        }
      },
      getTrainingTitleList (trainingTypeId) {
        // const trainingTitleList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.filter(item => item.status === 1)
        // if (categoryId) {
        //   return trainingTitleList.filter(item => item.training_type_id === categoryId)
        // }
        // return trainingTitleList
        const trainingTitleList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.filter(item => item.status === 1 && item.training_type_id === trainingTypeId).map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
        })
        return trainingTitleList
      },
      async loadCircularMemoNoRelatedInfos (datas) {
        if (datas) {
          this.formData.batch_id = 0
          this.formData.total_mark = 0
          this.load = true
          const params = {
            circular_memo_no: datas,
            personal_info_id: this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerCircularMemoSpecificList, params)
          if (!result.success) {
            this.formData.fiscal_year_id = 0
            this.formData.org_id = 0
            this.formData.training_title_id = 0
            this.formData.training_type_id = 0
            this.formData.sort = 2
            this.formData.details = []
            this.formData.batchDetails = []
            this.circularErrorMsg = 'Please Create Circular'
            this.load = false
          } else {
            this.circularErrorMsg = false
            const trData = result.circularMemoSpecimen
            this.formData.fiscal_year_id = trData.fiscal_year_id
            this.formData.org_id = trData.org_id
            this.formData.training_title_id = trData.training_title_id
            this.formData.training_type_id = trData.training_type_id
            this.load = false
            this.getBatchList(this.formData.org_id)
            this.loadData()
          }
        }
      },
      async loadBatchWiseTrinees (batchId) {
        if (batchId && this.formData.circular_memo_no) {
          // alert(this.formData.total_mark)
          this.formData.total_mark = 0
          this.load = true
          const params = {
            circular_memo_no: this.formData.circular_memo_no,
            personal_info_id: this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id,
            batch_id: batchId
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerCircularMemoSpecificList, params)
          if (!result.success) {
            this.formData.fiscal_year_id = 0
            this.formData.org_id = 0
            this.formData.training_title_id = 0
            this.formData.training_type_id = 0
            this.formData.sort = 2
            this.formData.details = []
            this.formData.batchDetails = []
            this.circularErrorMsg = 'Please Create Circular'
            this.load = false
          } else {
            this.circularErrorMsg = false
            const trData = result.circularMemoSpecimen
            this.formData.fiscal_year_id = trData.fiscal_year_id
            this.formData.org_id = trData.org_id
            this.formData.training_title_id = trData.training_title_id
            this.formData.training_type_id = trData.training_type_id
            this.load = false
            this.loadData()
          }
        }
      },
      async loadData () {
        const searchData = this.formData
        searchData.details = []
        searchData.personal_info_id = this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerBaseSpecificCircularList, searchData)
        if (result.success) {
          this.errorArr = []
          this.$refs.form.setErrors(this.errorArr)
          const datas = result.traineeBatches.map(item => {
            const desigObj = this.$store.state.Portal.orgList.find(designation => designation.id === parseInt(item.org_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.org = desigObj.org_name
              desigData.org_bn = desigObj.org_name_bn
            } else {
              desigData.org = ''
              desigData.org_bn = ''
            }
            const batchObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.find(doc => doc.value === parseInt(item.batch_id))
            const batchData = {}
            if (typeof batchObj !== 'undefined') {
              batchData.batch = batchObj.text_en
              batchData.batch_bn = batchObj.text_bn
            } else {
              batchData.batch = ''
              batchData.batch_bn = ''
            }
            return Object.assign({}, item, desigData, batchData)
          })
          if (this.formData.batch_id !== 0) {
            this.formData.total_mark = result.trainerTraineeBatchSpecificList.find(elm => elm.batch_id === this.formData.batch_id).total_mark
          }
          this.formData.details = datas
          this.formData.batchDetails = result.trainingApplicationBatchId
          if (result.trainerTotalMarkCollection.length > 0 || result.traineeObtainedMarkCollection.length > 0) {
            this.hasTotalOrObtainedMark = true
          } else {
            this.hasTotalOrObtainedMark = false
          }
        } else {
          this.formData.details = []
          this.formData.batchDetails = []
        }
      },
      async createData () {
        this.load = true
        let result = null
        result = await RestApi.postData(trainingElearningServiceBaseUrl, manualCourseEvaluationStore, this.formData)
        if (result.success) {
          // this.$socket.emit('send-notification', result.notification)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$refs.form.reset()
          // this.$router.push('/training-e-learning-service/tim/trainer-selection')
          this.$router.push('trainee-evaluation')
        } else {
          this.errorArr = result.errors
          this.$refs.form.setErrors(result.errors)
        }
        this.load = false
      }
    },
    watch: {
      'formData.circular_memo_no': function (newValue) {
        this.loadCircularMemoNoRelatedInfos(newValue)
      },
      'formData.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'formData.org_id': function (newValue) {
        this.getBatchList(newValue)
      },
      currentLocale: function () {
        if (this.formData.training_type_id) {
          this.trainingTitleList = this.getTrainingTitleList(this.formData.training_type_id)
        }
        if (this.formData.org_id) {
          this.getBatchList(this.formData.org_id)
        }
      },
      'formData.batch_id': function (newValue) {
        this.loadBatchWiseTrinees(newValue)
      }
    },
    mounted () {
      this.getCircularList()
      flatpickr('.fromDate', {})
    },
    beforeDestroy () {
      clearInterval(this.inputTimer)
    }
}
</script>
